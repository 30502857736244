import { default as indexio2u196kJJMeta } from "/home/forge/base.farbcode.net/releases/20250213061223/client/pages/auth/index.vue?macro=true";
import { default as indexuePIAtqmtfMeta } from "/home/forge/base.farbcode.net/releases/20250213061223/client/pages/auth/social/[organisation]/[provider]/index.vue?macro=true";
import { default as indexvNURtTig76Meta } from "/home/forge/base.farbcode.net/releases/20250213061223/client/pages/index.vue?macro=true";
import { default as organisationBaseData83ZUrmSJsgMeta } from "/home/forge/base.farbcode.net/releases/20250213061223/client/pages/organisations/[id]/organisationBaseData.vue?macro=true";
import { default as _91id_93uBnOFmSIOdMeta } from "/home/forge/base.farbcode.net/releases/20250213061223/client/pages/organisations/[id].vue?macro=true";
import { default as indexAtYmvmRmwMMeta } from "/home/forge/base.farbcode.net/releases/20250213061223/client/pages/organisations/index.vue?macro=true";
import { default as organisationsSURPmixpqQMeta } from "/home/forge/base.farbcode.net/releases/20250213061223/client/pages/organisations.vue?macro=true";
import { default as dungeonDJhXxT1QboMeta } from "/home/forge/base.farbcode.net/releases/20250213061223/client/pages/secret/index/dungeon.vue?macro=true";
import { default as inputsNvPeJOd58pMeta } from "/home/forge/base.farbcode.net/releases/20250213061223/client/pages/secret/index/inputs.vue?macro=true";
import { default as indexQY52TGVuXxMeta } from "/home/forge/base.farbcode.net/releases/20250213061223/client/pages/secret/index.vue?macro=true";
import { default as languageVdVjCU3jHcMeta } from "/home/forge/base.farbcode.net/releases/20250213061223/client/pages/settings/language.vue?macro=true";
import { default as _91id_93GpL6detn1AMeta } from "/home/forge/base.farbcode.net/releases/20250213061223/client/pages/settings/loginProviders/[id].vue?macro=true";
import { default as indexIbg058dD4kMeta } from "/home/forge/base.farbcode.net/releases/20250213061223/client/pages/settings/loginProviders/index.vue?macro=true";
import { default as _91_46_46_46slug_93yPlR9XJsH1Meta } from "/home/forge/base.farbcode.net/releases/20250213061223/client/pages/settings/mailTemplates/[name]/[...slug].vue?macro=true";
import { default as index0tN21iIuarMeta } from "/home/forge/base.farbcode.net/releases/20250213061223/client/pages/settings/mailTemplates/index.vue?macro=true";
import { default as settingsubWakBKITaMeta } from "/home/forge/base.farbcode.net/releases/20250213061223/client/pages/settings.vue?macro=true";
import { default as userActivitiesP3cwxR2447Meta } from "/home/forge/base.farbcode.net/releases/20250213061223/client/pages/users/[id]/userActivities.vue?macro=true";
import { default as userBaseDataKiWYpnOatYMeta } from "/home/forge/base.farbcode.net/releases/20250213061223/client/pages/users/[id]/userBaseData.vue?macro=true";
import { default as userCommunicationso3MhvK7ihMMeta } from "/home/forge/base.farbcode.net/releases/20250213061223/client/pages/users/[id]/userCommunications.vue?macro=true";
import { default as _91id_930MJxWvDML9Meta } from "/home/forge/base.farbcode.net/releases/20250213061223/client/pages/users/[id].vue?macro=true";
import { default as indexekgmWyAuy6Meta } from "/home/forge/base.farbcode.net/releases/20250213061223/client/pages/users/index.vue?macro=true";
import { default as usersuZaa5XOND3Meta } from "/home/forge/base.farbcode.net/releases/20250213061223/client/pages/users.vue?macro=true";
import { default as _91_46_46_46slug_93T1BXSHDBZUMeta } from "/home/forge/base.farbcode.net/releases/20250213061223/client/pages/wiki/[...slug].vue?macro=true";
export default [
  {
    name: "auth",
    path: "/auth",
    meta: indexio2u196kJJMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20250213061223/client/pages/auth/index.vue").then(m => m.default || m)
  },
  {
    name: "auth-social-organisation-provider",
    path: "/auth/social/:organisation()/:provider()",
    meta: indexuePIAtqmtfMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20250213061223/client/pages/auth/social/[organisation]/[provider]/index.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/forge/base.farbcode.net/releases/20250213061223/client/pages/index.vue").then(m => m.default || m)
  },
  {
    name: organisationsSURPmixpqQMeta?.name,
    path: "/organisations",
    meta: organisationsSURPmixpqQMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20250213061223/client/pages/organisations.vue").then(m => m.default || m),
    children: [
  {
    name: "organisations-id",
    path: ":id()",
    meta: _91id_93uBnOFmSIOdMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20250213061223/client/pages/organisations/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "organisations-id-organisationBaseData",
    path: "organisationBaseData",
    component: () => import("/home/forge/base.farbcode.net/releases/20250213061223/client/pages/organisations/[id]/organisationBaseData.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "organisations",
    path: "",
    meta: indexAtYmvmRmwMMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20250213061223/client/pages/organisations/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "secret",
    path: "/secret",
    component: () => import("/home/forge/base.farbcode.net/releases/20250213061223/client/pages/secret/index.vue").then(m => m.default || m),
    children: [
  {
    name: "secret-index-dungeon",
    path: "dungeon",
    component: () => import("/home/forge/base.farbcode.net/releases/20250213061223/client/pages/secret/index/dungeon.vue").then(m => m.default || m)
  },
  {
    name: "secret-index-inputs",
    path: "inputs",
    component: () => import("/home/forge/base.farbcode.net/releases/20250213061223/client/pages/secret/index/inputs.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "settings",
    path: "/settings",
    meta: settingsubWakBKITaMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20250213061223/client/pages/settings.vue").then(m => m.default || m),
    children: [
  {
    name: "settings-language",
    path: "language",
    component: () => import("/home/forge/base.farbcode.net/releases/20250213061223/client/pages/settings/language.vue").then(m => m.default || m)
  },
  {
    name: "settings-loginProviders-id",
    path: "loginProviders/:id()",
    meta: _91id_93GpL6detn1AMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20250213061223/client/pages/settings/loginProviders/[id].vue").then(m => m.default || m)
  },
  {
    name: "settings-loginProviders",
    path: "loginProviders",
    meta: indexIbg058dD4kMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20250213061223/client/pages/settings/loginProviders/index.vue").then(m => m.default || m)
  },
  {
    name: "settings-mailTemplates-name-slug",
    path: "mailTemplates/:name()/:slug(.*)*",
    meta: _91_46_46_46slug_93yPlR9XJsH1Meta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20250213061223/client/pages/settings/mailTemplates/[name]/[...slug].vue").then(m => m.default || m)
  },
  {
    name: "settings-mailTemplates",
    path: "mailTemplates",
    meta: index0tN21iIuarMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20250213061223/client/pages/settings/mailTemplates/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: usersuZaa5XOND3Meta?.name,
    path: "/users",
    meta: usersuZaa5XOND3Meta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20250213061223/client/pages/users.vue").then(m => m.default || m),
    children: [
  {
    name: "users-id",
    path: ":id()",
    meta: _91id_930MJxWvDML9Meta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20250213061223/client/pages/users/[id].vue").then(m => m.default || m),
    children: [
  {
    name: "users-id-userActivities",
    path: "userActivities",
    component: () => import("/home/forge/base.farbcode.net/releases/20250213061223/client/pages/users/[id]/userActivities.vue").then(m => m.default || m)
  },
  {
    name: "users-id-userBaseData",
    path: "userBaseData",
    meta: userBaseDataKiWYpnOatYMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20250213061223/client/pages/users/[id]/userBaseData.vue").then(m => m.default || m)
  },
  {
    name: "users-id-userCommunications",
    path: "userCommunications",
    component: () => import("/home/forge/base.farbcode.net/releases/20250213061223/client/pages/users/[id]/userCommunications.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "users",
    path: "",
    meta: indexekgmWyAuy6Meta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20250213061223/client/pages/users/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "wiki-slug",
    path: "/wiki/:slug(.*)*",
    meta: _91_46_46_46slug_93T1BXSHDBZUMeta || {},
    component: () => import("/home/forge/base.farbcode.net/releases/20250213061223/client/pages/wiki/[...slug].vue").then(m => m.default || m)
  }
]