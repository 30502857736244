import validate from "/home/forge/base.farbcode.net/releases/20250213061223/client/node_modules/nuxt/dist/pages/runtime/validate.js";
import current_45module_45global from "/home/forge/base.farbcode.net/releases/20250213061223/client/middleware/currentModule.global.js";
import is_45authenticated_45global from "/home/forge/base.farbcode.net/releases/20250213061223/client/middleware/isAuthenticated.global.js";
import manifest_45route_45rule from "/home/forge/base.farbcode.net/releases/20250213061223/client/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  current_45module_45global,
  is_45authenticated_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  auth: () => import("/home/forge/base.farbcode.net/releases/20250213061223/client/middleware/auth.ts"),
  "can-access-route": () => import("/home/forge/base.farbcode.net/releases/20250213061223/client/middleware/canAccessRoute.js"),
  guest: () => import("/home/forge/base.farbcode.net/releases/20250213061223/client/middleware/guest.ts"),
  unverified: () => import("/home/forge/base.farbcode.net/releases/20250213061223/client/middleware/unverified.ts"),
  verified: () => import("/home/forge/base.farbcode.net/releases/20250213061223/client/middleware/verified.ts")
}